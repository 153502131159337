import React from 'react';
import {
  useLocation,
  useParams,
} from 'react-router-dom';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import { ADD } from 'routes/Routes';
import PropertyDetails from 'pages/agency-app/appartments/propertyDetails/PropertyDetails';
import { PropertyForm } from 'types/Property';

type Param = {
  id: string,
};

export default function PropertyDetailsContainer() {
  const location = useLocation<{ candidateId?: string, propertyCreation?: PropertyForm }>();
  const { id } = useParams<Param>();
  const propertiesQueries = usePropertiesBackend();
  const { getProperty } = propertiesQueries;
  const { data: property, isLoading } = getProperty(id, () => {
  }, id !== ADD);
  if (isLoading) {
    return <div>loading</div>;
  }
  const propertyCreation = location.state?.propertyCreation;
  const candidateId = location.state?.candidateId;
  return (
    <PropertyDetails
      property={property}
      propertyCreation={propertyCreation}
      candidateId={candidateId}
    />
  );
}
