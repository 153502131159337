import React, { useState } from 'react';
import Image from 'theme/Image';
import { IconButton } from '@material-ui/core';
import {
  Delete,
  Download,
} from '@material-ui/icons';
import PdfPreview from 'pages/common/PdfPreview';
import FileDisplay from 'pages/common/FileDisplay';
import DialogWrapper from './DialogWrapper';
import { useFilesBackend } from 'network/queries/FileQueries';

type Props = {
  src: string,
  isPdf?: boolean;
  onDelete?: () => void
  disabled?: boolean
};

export default function FilePreview(
  {
    src,
    isPdf,
    onDelete,
    disabled,
  }: Props,
) {
  const { getFile } = useFilesBackend();
  const { data: fileUrl } = getFile(src);
  const [showPreview, setShowPreview] = useState(false);
  return (
    <>

      <div role="presentation" onClick={() => setShowPreview(true)} className="file-preview">
        {
          isPdf || src.endsWith('.pdf') ? (
            <PdfPreview src={fileUrl} />
          ) : (
            <Image alt="document" src={fileUrl} />
          )
        }
        <div
          className="preview-actions-container"
        >
          <div
            className="preview-actions"
            role="presentation"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <a href={fileUrl} download={src}>
              <IconButton>
                <Download />
              </IconButton>
            </a>
            {
              onDelete && (
                <IconButton disabled={disabled} onClick={onDelete}>
                  <Delete />
                </IconButton>
              )
            }
          </div>
        </div>
      </div>
      {
        showPreview && (
          <DialogWrapper open onClose={() => setShowPreview(false)}>
            <FileDisplay src={fileUrl} isPdf={src.endsWith('.pdf')} />
          </DialogWrapper>
        )
      }
    </>
  );
}
