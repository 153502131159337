import React, {
  useCallback,
  useState,
} from 'react';
import Messages from 'services/i18n/Messages';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import OnBoardingForm from 'pages/authentification/OnBoardingForm';
import firebase from 'firebase/compat';
import { useAuth } from 'reactfire';
import { useHistory } from 'react-router-dom';
import { DASHBOARD } from 'routes/Routes';

export default function OnBoarding() {
  const agentQueries = useAgentsBackend();
  const { getAgent } = agentQueries;
  const { data: agent } = getAgent();
  const auth = useAuth();
  const history = useHistory();
  const [mailError, setMailError] = useState('');

  const uiCallback = useCallback((ui) => {
    ui.disableAutoSignIn();
  }, []);

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        if (agent && authResult.user.email === agent.email) {
          history.push(DASHBOARD);
        } else {
          setMailError(Messages.t('error.invalidMail'));
        }
        return false;
      },
    },
  };

  if (!agent) {
    return <div>...Loading</div>;
  }

  return (
    <div className="login-page">
      <h1>Lokaa</h1>
      <div className="login-card">
        <h5>{Messages.t('onboarding.createPassword')}</h5>
        <OnBoardingForm agent={agent} />
        <h5>{Messages.t('generics.or')}</h5>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={auth}
          uiCallback={uiCallback}
        />
        <div className="mail-error">{mailError}</div>
      </div>
    </div>
  );
}
