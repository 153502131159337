import { Traduction } from 'services/i18n/Traduction';

const fr = {
  'history.modification': 'Modification',
  'history.date': 'Date',
  'history.author': 'Auteur',
  'parameters.agencyInfo': 'Infos agences',
  'calendar.warning.sync': 'Synchroniser mon agenda',
  'calendar.warning.current': 'Attention, vous n\'avez aucun agenda synchronisé.',
  'calendar.warning': 'Attention aucun agenda externe n\'est synchronisé pour cet agent.',
  'property.select': 'Sélectionnez une annonce',
  'visit.showProfile': 'Voir le profil',
  'visit.candidateInformation': 'Information du visiteur',
  'visit.assignProperty': 'assigner une annonce',
  'visit.youMust': 'Vous devez ',
  'visit.createCandidate': 'Créer un nouveau candidat',
  'visit.date.fromTo': '%{startDate} à %{endDate}',
  'visit.dateFormat': 'eee dd MMM \'de\' HH:mm',
  'visit.unable': 'Une erreur s\'est produite lors de la proposition de visite',
  'visit.invitationSend': 'La proposition de visite a été envoyée',
  'confirmation.newVisit.title': 'Renvoyer demande de visite',
  'confirmation.newVisit.message': 'Envoyer une nouvelle proposition de visite au candidat ?',
  'visit.canceled': 'La visite a été annulée',
  'confirmation.cancelVisit.title': 'Annuler la visite',
  'confirmation.cancelVisit.message': 'Vous êtes sur le point d\'annuler cette visite',
  'visitSlot.bookedVisit': '%{bookedVisit}/%{total} visites programmées',
  'agenda.title': 'Agenda',
  'integration.done': 'Vous êtes connecté',
  'integration.disconnect': 'Vous êtes sur le point de vous déconnecter',
  'integration.google.title': 'Google calendar',
  'integration.google.action': 'Connecter',
  'integration.google.description': 'Connectez-vous à votre compte google',
  'integration.outlook.title': 'Outlook',
  'integration.outlook.action': 'Connecter',
  'integration.outlook.description': 'Connectez-vous à votre compte microsoft',
  'visitInvitation.missingSlot': 'Aucun créneau existant ou RDV disponible',
  'visitInvitation.missingSlot.message': 'Vous devez d’abord créer des créneaux de visite '
    + 'pour ces biens ',
  'visitInvitation.missingProperty': 'Aucune annonce',
  'visitInvitation.missingProperty.message': 'Vous devez d’abord assigner des annonces à ces candidatures',
  'visit.add': '+ Ajouter une visite',
  'visit.create': 'Créer une visite',
  'visit.create.message': 'Envoyer une invitation au candidat pour une visite '
    + 'à l’horaire qui vous convient ',
  'visit.available': '%{available} / %{total} places dispo',
  'visit.slotCreationHours': 'Visites du %{day} de %{hourStart} à %{hourEnd}',
  'field.password': 'Mot de passe',
  'field.passwordChange': 'Nouveau mot de passe',
  'field.mail': 'Email',
  'formButton.confirm': 'Confirmer',
  'field.missingDocuments': 'Préciser la liste des documents manquants',
  'formButton.modify': 'Modifier',
  'formButton.add': 'Ajouter',
  'formButton.at': 'à',
  'calendar.visit.noEvent': 'Vous n’avez pas de visite planifiée sur la période sélectionnée',
  'calendar.visitSlot.noEvent': 'Vous n’avez pas de créneaux de visite planifiée sur la période sélectionnée',
  'calendar.select.noEvent': 'Aucun évènement sur cette période',
  'calendar.select.agent': 'Vous devez sélectionner un agent',
  'visitSlot.form.title': 'Créneau de visite',
  'visit.form.title': 'Visite',
  'field.error.interfereWithExistingVisit': 'Interfere avec une visite existante',
  'candidate.select': 'Sélectionnez un candidat',
  'candidate.selectedContacts': '%{number} contacts sélectionnés',
  'candidate.proposeViewing': 'Proposer un créneau de visite',
  'candidate.proposeViewing.renewal': ' (relance)',
  'candidate.proposeViewing.message': 'Proposer directement au candidat tous les créneaux de visites disponibles',
  'formButton.selectAll': 'Tout sélectionner',
  'formButton.selectNone': 'Tout désélectionner',
  'formButton.edit': 'Modifier',
  'formButton.addContact': 'Ajouter un locataire',
  'formButton.addGarant': 'Ajouter un garant',
  'formButton.update': 'Mettre à jour',
  'formButton.addPropertyByUrl': 'Ajouter une propriété par url',
  'formButton.createManually': 'Créer manuellement',
  'formButton.back': 'Retour',
  'formButton.download': 'Télécharger',
  'formButton.downloadAll': 'Télécharger tout',
  'formButton.cancel': 'Annuler',
  'formButton.close': 'Fermer',
  'formButton.save': 'Confirmer',
  'formButton.delete': 'Supprimer',
  'formButton.connect': 'Se connecter',
  'formButton.sendConnectionMail': 'Recevoir le mail de connexion',
  'formButton.passwordConnection': 'Se connecter par email et mot de passe',
  'formButton.ssoConnection': 'Se connecter avec l\'authentification unique',
  'generics.all': 'N/A',
  'generics.or': 'OU',
  'generics.YES': 'Oui',
  'generics.superior': 'Supérieur',
  'generics.inferior': 'Inférieur',
  'property.seeCandidate': 'Voir les candidatures',
  'property.seeVisits': 'Voir les visites',
  'generics.NO': 'Non',
  'generics.none': 'Aucun',
  'generics.every': 'Tous',
  'generics.MONDAY': 'Lundi',
  'generics.TUESDAY': 'Mardi',
  'generics.WEDNESDAY': 'Mercredi',
  'generics.THURSDAY': 'Jeudi',
  'generics.FRIDAY': 'Vendredi',
  'generics.SATURDAY': 'Samedi',
  'generics.SUNDAY': 'Dimanche',
  'navBar.dashboard': 'Dashboard',
  'navBar.appartment': 'Annonces',
  'navBar.candidates': 'Candidats',
  'navBar.agenda': 'Agenda',
  'plan.visitCount.almostReach': 'Limite de création de créneaux de visite bientôt atteinte',
  'plan.visitCount.reach': 'Limite de création de créneaux de visite atteinte',
  'plan.premium': 'Passer premium',
  'plan.premium.advantages': 'et créez un nombre illimité de créneaux de visite !',
  'plan.premium.advantages.full': 'Passez premium et créez un nombre illimité de créneaux de visite !',

  'plan.visitSlot': '%{current}/%{total} créneaux',
  'field.passwordConfirm': 'Confirmez votre mot de passe',
  'field.notMatchingPasswor': 'Les mots de passe ne correspondent pas',
  'navBar.parameters': 'Paramètres',
  'navBar.changeAccount': 'Changer de compte',
  'button.logout': 'Déconnexion',
  'dates.publishedDate.unknown': 'unknown',
  'dates.publishedDate.sinceDate': 'Il y a ',
  'dates.publishedDate.since': 'Il y a %{since}',
  'dates.publishedDate.now': 'A l\'instant',
  'dates.publishedDate.minutes': '%{deltaInMinutes} min',
  'dates.publishedDate.hours': '%{deltaInHours} h',
  'dates.publishedDate.days': '%{deltaInDays} jours',
  'dates.publishedDate.months': '%{deltaInMonths} mois',
  'dates.publishedDate.years': '%{deltaInYears} ans',
  'dates.today': 'Aujourd\'hui',
  'field.search': 'Recherche',
  'agent.createForAdmin': 'Hérité du role gestionnaire',
  'admin.revokeAdmin': 'Retirer l\'accès admin',
  'admin.giveAdmin': 'Donner admin',
  'field.city': 'Ville',
  'field.agent': 'Agent',
  'agency.select': 'Choisissez une agence',
  'dates.dayDiff': 'Jour %{current}/%{total}',
  'user.connectWith': 'Vous êtes connecté avec : %{mail}',
  'user.notYou': 'Ce n\'est pas vous ?',
  'dates.allDay': 'Toute la journée',
  'agent.agencies': 'Vos agences',
  'field.description': 'Description',
  'field.rooms': 'Pièces',
  'field.bedrooms': 'Chambres',
  'field.url': 'Url',
  'field.floor': 'Etage',
  'field.price': 'Loyer charges comprises',
  'field.area': 'Surface',
  'field.bathrooms': 'Salles d\'eau',
  'field.furnished': 'Meublé',
  'field.address': 'Adresse',
  'field.zipcode': 'Code postal',
  'field.street': 'Numéro et rue',
  'field.firstName': 'Prénom',
  'field.lastName': 'Nom',
  'field.email': 'Email',
  'field.france': 'France',
  'field.forwardCallMandatory': 'Vous devez avoir un numero de telephone renseigné pour mettre a jour ce paramètre',
  'field.forwardCall': 'Systématiquement',
  'field.forwardOnlyIfNotAvailable': 'Seulement si mon standard est indisponible',
  'field.mailType': 'Type de mail',
  'field.comment': 'Commentaire',
  'field.country': 'Pays',
  'field.property': 'Annonce',
  'field.startDate': 'Date de début',
  'field.endDate': 'Date de fin',
  'field.visitDurationMinutes': 'Durée d\'une visite',
  'field.maxVisitors': 'Visiteurs simultanés max',
  'field.agentDataVisible': 'Date de fin',
  'field.agentSelected': 'Responsable des visites',
  'field.visitsAddress': 'Adresse des visites',
  'visit.alreadyExist': 'Une visite est déjà programmée avec ce candidat',
  'visits.proposed': '%{number} visites seront proposées sur ce créneau',
  'visitSlot.sameProperty': 'D\'autres créneaux de visite existent déjà dans le futur pour ce bien. ',
  'visitSlot.sameProperty.show': 'Les voir',
  'field.error.interferingSlotDates': 'Les dates de ce créneau chevauchent celles d\'un créneau existant sur la même annonce',
  'field.propertyAddress': 'Adresse du bien',
  'field.enterprise': 'Entreprise',
  'field.status': 'Statut',
  'field.note': 'Ecrire une note',
  'field.noteToGuests': 'Ecrire une note à destination des invités',
  'field.candidate': 'Candidat',
  'field.sfcOptOne': 'L\'appelant entendra ce message systématiquement.',
  'field.sfcOptTwo': 'L\'appelant entendra ce message si l\'agence est fermée ou bien si personne ne répond à son appel pendant les heures d\'ouverture.',
  'field.sfcOptTwoSms': 'Le SMS sera systématiquement envoyé suite à un appel entrant.',
  'price.byMonth': '/ mois',
  'field.generalInformations': 'Informations générales',
  'field.location': 'Localisation',
  'field.occupantQualification': 'Qualification',
  'field.occupantQualificationLong': 'Qualification des locataires',
  'form.action.manuallyEnterAddress': 'Saisir l\'adresse manuellement',
  'status.online': 'En ligne',
  'status.offline': 'Hors ligne',
  'status.turnOnline': 'Remettre en ligne',
  'status.turnOffline': 'Mettre hors ligne',
  'property.archived': 'Archivée',
  'property.GLI': 'Garantie Loyer Impayé',
  'property.enableVisitAutomation': 'Envoi automatique des créneaux de visite',
  'property.GLIShort': 'GLI',
  'property.pinel': 'Pinel',
  'status.unassigned': 'L\'annonce n\'a pas encore été assignée à un agent !',
  'status.noProperty': 'Pas de bien lié',
  'image.add': 'Ajouter',
  'image.sort': 'Faites glisser vos photos pour changer leur ordre',
  'image.addPhoto': 'Ajouter une image ou changer l\'ordre',
  'dragnDrop.dropHere': 'Cliquez ou déposez votre image ici',
  'pageSelector.list': 'Liste',
  'pageSelector.map': 'Carte',
  'pageSelector.board': 'Board',
  'pageSelector.tab': 'Liste',
  'pageSelector.profil': 'Individuel',
  'pageSelector.agency': 'Agence',
  'pageSelector.plans': 'Abonnement',
  'pageSelector.visit': 'Visites',
  'pageSelector.visitSlot': 'Créneaux de visites',
  'agenda.showMyAgenda': 'Consulter mon agenda',
  'pageSelector.integrations': 'Integrations',
  'pageSelector.redirection': 'Messagerie vocale',
  'pageSelector.occupantQualification': 'Qualification des locataires',
  'settings.redirectionIntro': 'La messagerie vocale Lokaa redirige tous vos appels entrants vers votre formulaire de demande de visite.',
  'pageSelector.manage': 'Manage',
  'parameters.agencyData': 'Données Agence',
  'parameters.open': 'Ouvert',
  'parameters.visitSlotPlan': 'Plan creation de créneaux de visites activé',
  'parameters.maxFreeVisitSlot': 'Nombre de créneaux de visites gratuit maximum',
  'parameters.plan': 'Abonnement',
  'parameters.plan.success.title': '🎉 Félicitations 🎉',
  'parameters.plan.success.subtitle': 'Notre équipe va effectuer la mise à niveau de votre compte au plus tôt',
  'parameters.plan.success.message': 'Vous pourrez très vite profiter pleinement des fonctionnalités de Lokaa Pro au sein de votre agence',
  'parameters.plan.current': 'Abonnement actuel :',
  'parameters.plan.noAdmin': 'Seul un utilisateur au statut d\'administrateur peut modifier l\'abonnement de votre agence. Merci de vous rapprocher de la ou des personne(s) suivante(s) pour effectuer la mise à niveau : ',
  'parameters.plan.free': 'Gratuit',
  'pageSelector.futurVisit': 'Visites à venir',
  'pageSelector.pastVisit': 'Visites passées',
  'parameters.plan.premium': 'Premium',
  'parameters.plan.premium.include': 'Votre plan Premium inclut la création de créneaux de visite en illimité',
  'parameters.plan.premium.message': 'Contactez notre équipe support pour toute question ou modification concernant votre abonnement',
  'parameters.plan.limitedTo': 'Le plan gratuit est limité à %{limit} créations de créneaux de visite',
  'parameters.plan.Message': 'Mettez à niveau votre abonnement pour profiter de la planification de visites en illimité',
  'parameters.plan.price': '70',
  'parameters.plan.payment.message': 'Création de créneaux de visite illimitée',
  'parameters.plan.payment.switchPremium': 'Passer premium',
  'parameters.plan.payment.noCondition': 'Sans engagement',
  'parameters.openHoliday': 'Garder le standard ouvert les jours feriés',
  'parameters.close': 'Fermé',
  'parameters.agents': 'Agents',
  'parameters.personalDatas': 'Données personnelles',
  'parameters.modifyPassword': 'Modifier le mot de passe',
  'parameters.notification': 'Notifications',
  'parameters.notificationAgencyPreferences': 'Préférences de notifications pour l\'email alternatif',
  'parameters.notificationTooltip': 'Cette adresse email sera utilisée pour vous notifier des candidatures lorsque qu\'aucun agent n\'est assigné à une annonce',
  'parameters.notificationTooltipNotAdmin': 'Seul l\'administrateur du compte agence peut modifier ce champs !',
  'parameters.redirectionTitle': 'Gestion des appels entrants',
  'parameters.redirectionSubtitle': 'Quand voulez-vous déclencher la messagerie vocale Lokaa ?',
  'parameters.rings': '2 - Délai de sonnerie',
  'parameters.ringLabel': 'Sonne',
  'parameters.ringsMoreInfo': 'Pas de réponse après',
  'parameters.openingHours': 'Horaires du standard',
  'parameters.forwardCallAdvise': 'Pour que la redirection vers le message vocal Lokaa fonctionne, vous devez désactiver tout message d\'accueil ou musique de mise en attente sur votre standard téléphonique.',
  'parameters.playAudio': 'Message vocal Lokaa',
  'parameters.notOpen': 'En dehors des horaires de standard indiqués, le contact sera redirigé vers la boite vocale Lokaa.',
  'agent.setRole': 'Nommer %{role}',
  'agent.setAdmin': 'Nommer administrateur',
  'agent.remove': 'Retirer',
  'property.remove': 'Retirer l\'annonce',
  'property.activate': 'Activer l\'annonce',
  'candidate.refuse': 'Refuser',
  'candidate.askDocuments': 'Demander les dossiers',
  'candidate.inviteVisit': 'Inviter à une visite',
  'candidate.unRefuse': 'Remettre',
  'filter.All': 'Tous',
  'filter.None': 'Aucun',
  'field.phoneNumber': 'Téléphone',
  'field.SameNotificationMail': 'Utiliser l\'email de l\'agence : %{mail}',
  'field.clientFormURL': 'Formulaire de visite',
  'field.adPhoneNumber': 'Téléphone annonce',
  'field.adEmail': 'Email annonce',
  'field.identifier': 'Référence',
  'field.situation': 'Situation',
  'field.gliEgible': 'Eligibilité à la GLI',
  'field.profession': 'Profession',
  'field.leaseType': 'Type de contrat',
  'field.monthlyIncome': 'Revenus mensuels net',
  'field.trialPeriod': 'Période d\'essai validée',
  'field.professionalDatas': 'Données Professionnelles',
  'field.personalDatas': 'Données Personnelles',
  'field.propertyRelated': 'L\'appartement',
  'field.candidatureRelated': 'La candidature',
  'field.garant': 'Garant',
  'case.create': 'Ajouter des documents',
  'field.garants': 'Garants',
  'field.caseIcome': 'Revenus foyer',
  'field.contact': 'Locataire',
  'field.garantDatas': 'Données du Garant',
  'property.publishDate': 'Publié %{date}',
  'property.unPublishDate': 'Dépublié %{date}',
  'field.notation': 'Pertinence',
  'field.document': 'Documents',
  'field.lastActivty': 'Dernière activité',
  'field.moveInWanted': 'Emménagement souhaité',
  'field.occupantsRelationship': 'Relation entre locataires',
  'field.source': 'Source',
  'field.plateform': 'Plateforme',
  'field.canal': 'Canal',
  'field.imageUrl': 'Url du logo',
  'field.error.emailAlreadyUsed': 'Ce nom d\'agence n\'est pas disponible',
  'field.error.freeVisitSlotCountReach': 'Le nombre de créneaux de visites gratuits est atteint',
  'field.message': 'Message mail',
  'field.messageForm': 'Message formulaire',
  'field.motivation': 'Message de motivation',
  'field.propertyDescription': 'Description de l\'annonce',
  'field.personalSituation': 'Situation personnelle',
  'field.professionalSituation': 'Situation professionnelle',
  'field.step': 'Statut',
  'field.type': 'Type',
  'field.name': 'Nom',
  'field.notes': 'Notes',
  'field.amount': 'Montant maximum garanti',
  'field.fullname': 'Nom',
  'field.agencyName': 'Nom de l\'agence',
  'field.adminEmail': 'Email de l\'administrateur',
  'parameters.steps': 'Statuts',
  'parameters.seconds': 'secondes',
  'parameters.listingData': 'Données de l\'annonce',
  'parameters.errorClosingHour': 'L\'heure de fermeture doit être postérieur à l\'heure d\'ouverture',
  'parameters.phoneLineOpening': 'Ouverture du standard',
  'parameters.phoneLineClosing': 'Fermeture du standard',
  'parameters.sendSms': 'Envoi du SMS avec fiche de renseignement à remplir :',
  'parameters.smsContent': 'Vous venez de nous appeler pour un bien à louer.<br/>'
    + 'Pour le visiter, merci de compléter notre fiche info : %{url}',
  'parameters.phoneRedirect': 'Transfert vers le standard %{agencyName} (%{agencyPhone})',
  'parameters.phoneRedirectSchemaTitle': 'Sonne sur<br/>'
    + 'le numéro d\'annonce Lokaa',
  'parameters.occupantQualif.GLI': 'Garantie des Loyers Impayés',
  'parameters.occupantQualif.GLI.activateQuestion': 'Souhaitez-vous paramétrer un contrat GLI ?',
  'parameters.occupantQualif.GLI.selectSituations': 'Sélectionnez les situations professionnelles éligibles à votre contrat GLI :',
  'parameters.occupantQualif.GLI.defineRatioStudentP1': 'Somme des revenus nets des garants éligibles égal ou supérieure à',
  'parameters.occupantQualif.GLI.defineRatioOccupantsP1': 'Somme des revenus nets des locataires éligibles égal ou supérieure à',
  'parameters.occupantQualif.GLI.defineRationP2': 'fois le montant du loyer charges comprises',
  'parameters.occupantQualif.Pinel': 'Pinel',
  'formButton.submitting': 'Chargement',
  'notification.type': 'type',
  'notification.NONE': 'None',
  'notification.MAIL': 'Mail',
  'garantType.INSURANCE': 'Assurance locative',
  'garantType.BANK': 'Banque',
  'garantType.ENTERPRISE': 'Entreprise',
  'garantType.PERSON': 'Personne physique',
  'dashboard.onlineAnounce': 'Annonces en ligne',
  'dashboard.viewingRequest': 'Demandes de visite traitées',
  'dashboard.activities': 'Activités récentes',
  'dashboard.statistics': 'Statistiques',
  'notifications.announceCreate': 'Votre annonce a bien été ajoutée dans la liste',
  'notifications.contactCreate': 'La candidature a bien été ajoutée dans la liste',
  'notifications.stepCreate': 'Le statut a bien été ajoutée dans la liste',
  'notifications.mailSend': 'Un mail de connexion vous a été envoyé',
  'notifications.documentMailSend': 'Un mail a bien été envoyé',
  'notifications.agentCreate': 'L\'agent a bien été créé et un mail de connexion lui a été envoyé',
  'notifications.visitSlotCreate': 'Le créneau de visite a bien été créé',
  'notifications.update': 'Vos modifications ont bien été prises en compte',
  'notifications.error': 'Une erreur s\'est produite',
  'notifications.delete': 'Donnée supprimée',
  'notifications.document.delete': 'Document supprimé',
  'notifications.openTimeslotCreate': 'Créneau d\'ouverture créé avec succès',
  'notifications.case.created': 'Le dossier a bien été créé',
  'document.missing': 'Le candidat a indiqué qu\'il n\'avait pas ce document',
  'document.missing.explain': 'Explication du candidat',
  'onboarding.createPassword': 'Créer votre mot de passe',
  'error.invalidMail': 'Mail invalide',
  'error.expiredToken': 'Votre lien de connexion est expiré, veuillez indiquer votre email ci-dessous pour en recevoir un nouveau.',
  'error.passwordInvalid': 'La combinaison mot de passe / email est invalide',
  'form.error.requierd': 'Champ requis',
  'field.error.requierd': 'Champ requis',
  'field.error.mustBeFuture': 'Doit être futur',
  'field.error.mustBeAfterStartDate': 'Doit être après la date de début',
  'field.error.unavailableSlot': 'Cet horaire n\'est plus disponible',
  'form.error.email': 'Email invalide',
  'form.error.password': 'Votre mot de passe doit respecter les règles suivantes : \n-Au moins 8 caractères\n-Au moins une majuscule\n-Au moins une minuscule\n-Au moins un chiffre\n-Au moins un caractère spécial',
  'document.tag.PENDING': 'En attente',
  'form.error.GarantDuplicateName': 'Un garant avec le même nom existe déjà.',
  'form.error.GarantDuplicate': 'Un garant avec le même nom existe déjà. Merci de préciser le nom du garant avec le nom du locataire concerné. Par exemple : Garantme John Doe',
  'form.error.emailDuplicate': 'Cet email est déjà présent sur cette candidature. Insérez une adresse email différente ou laissez le champ vide',
  'form.error.phoneNumberDuplicate': 'Ce numéro de téléphone est déjà présent sur cette candidature. Insérez une adresse email différente ou laissez le champ vide',
  'form.error.enableVisitQualification': 'Pour activer l\'envoi de visite automatique vous devez au moins sélectionner une qualification GLI ou Pinel',
  'form.error.enableVisitQualificationSlot': 'Vous devez au moins avoir un créneau de visite dans le futur',
  'property.generateIdentifier': 'Générer',
  'property.delete': 'Supprimer l\'annonce',
  'field.error.property.add.notScrap': 'Votre URL est invalide ou nous ne pouvons pas récuperer d\'informations depuis ce site',
  'field.error.property.add.processing': 'Nous sommes en train de récuperer les informations sur cette annonce. Merci de réessayer dans quelques instants',
  'agent.addAgent': 'Ajouter un agent',
  'property.archive': 'Archiver l\'annonce',
  'property.seeListing': 'Voir l\'annonce',
  'property.unArchive': 'Désarchiver',
  'property.createVisitSlot': 'Créer un créneau de visite',
  'property.createVisit': 'Créer une visite',
  'property.noLink': 'Lien non disponible',
  'property.chooseProperty': 'Quelle est la propriété concernée ?',
  'field.isArchived': 'Disponibilité',
  'filter.isArchived': 'Annonces archivées',
  'filter.isNotArchived': 'Logements disponibles',
  'confirmation.archivage.title': 'Voulez-vous archiver cette annonce ?',
  'confirmation.archivage.message': 'Toutes les informations de l\'annonce seront conservées et vous pourrez toujours accéder aux candidatures associées à cette annonce.',
  'confirmation.archivage.action': 'Archiver',
  'confirmation.deleteProperty.title': 'Attention : vous allez supprimer définitivement cette annonce',
  'confirmation.deleteProperty.message': 'Attention : Vous ne pourrez plus accéder aux candidatures associées à cette annonce. <br/>'
    + '<br/>'
    + 'La suppression d\'une annonce et des candidatures associées est définitive. ',
  'confirmation.deleteProperty.action': 'Supprimer',
  'confirmation.deleteProperty.archive': 'Archiver cette annonce au lieu de la supprimer',
  'placeType.airport': 'Airport',
  'placeType.amusement_park': 'Park',
  'placeType.aquarium': 'Aquarium',
  'placeType.art_gallery': 'Gallery',
  'placeType.bank': 'Bank',
  'placeType.bar': 'Bar',
  'placeType.bus_station': 'Bus station',
  'placeType.gym': 'Gym',
  'placeType.hospital': 'Hospital',
  'placeType.light_rail_station': 'Light rail',
  'placeType.museum': 'Museum',
  'placeType.shopping_mall': 'Mall',
  'placeType.restaurant': 'Restaurant',
  'placeType.stadium': 'Stadium',
  'placeType.train_station': 'Train station',
  'placeType.subway_station': 'Subway',
  'placeType.university': 'University',
  'form.error.default': 'Ce champ est invalide',
  'form.error.name': 'Veuillez renseigner au moins un nom ou un prénom',
  'form.error.contact': 'Veuillez renseigner au moins un numéro de téléphone ou un email',
  'personalSituations.MARRIED': 'Marié(e) ou pacsé(e)',
  'personalSituations.SINGLE': 'Célibataire',
  'personalSituations.COUPLE': 'Union libre / concubinage',
  'personalSituations.SPLITED': 'Divorcé(e) ou séparé(e)',
  'personalSituations.WIDOWER': 'Veuf(ve)',
  'personalSituations.OTHER': 'Autre',
  'professionalSituation.EMPLOYED': 'Salarié',
  'professionalSituation.RETIRED': 'Retraité',
  'professionalSituation.STUDENT': 'Etudiant / Stagiaire',
  'professionalSituation.FREELANCER': 'Indépendant / Profession libérale',
  'professionalSituation.OFFICIAL': 'Fonctionnaire',
  'professionalSituation.JOB_SEEKER': 'Demandeur d\'emploi',
  'contractType.CDD': 'CDD',
  'contractType.CDI': 'CDI',
  'contractType.Alternant': 'Alternance',
  'contractType.Interimaire': 'Intérim',
  'contractType.Intermittent': 'Intermittent',
  'field.notificationMail': 'Email alternatif de notification',
  'snack.noMailDefined': 'Attention ! Vous n\'avez pas encore défini d\'adresse email alternative pour recevoir les notifications dans le cas où aucun agent n\'est assigné sur une annonce. Vous ne serez pas averti en cas de nouvelle candidature sur vos logements si aucun agent en charge n\'est renseigné.',
  'snack.noMailDefined.link': ' Définir une adresse e-mail alternative de réception des notifications',
  'snack.missingAgent': 'Attention ! Certaines de vos annonces n\'ont pas d\'agent assigné. Toutes les notifications sur les candidatures relatives à ces annonces seront envoyées vers l\'adresse email suivante : %{mail}',
  'snack.missingAgentNoMail': 'Attention ! Certaines de vos annonces n\'ont pas d\'agent assigné. Aucun agent ne sera notifié pour les candidatures liées à ces annonces. L\'administrateur du compte peut renseigner une adresse email alternative pour recevoir les notifications.',
  'snack.missingAgent.link': ' Désigner les agents en charge sur les annonces non assignées',
  'agent.welcome': 'Bienvenue sur Lokaa',
  'agent.finalize': 'Finalisez le paramétrage de votre compte en remplissant les champs suivants',
  'confirmation.deleteCandidature.title': 'Supprimer une candidature',
  'confirmation.deleteCandidature.message': 'La suppression d’une candidature est définitive et irréversible.<br/>'
    + '<br/>'
    + 'Êtes-vous certain de vouloir supprimer cette candidature ?',
  'confirmation.deleteCandidature.action': 'Supprimer',
  'confirmation.deleteGarant.title': 'Supprimer un garant',
  'confirmation.deleteGarant.message': 'La suppression d’un garant est définitive et irréversible.<br/>'
    + '<br/>'
    + 'Êtes-vous certain de vouloir supprimer ce garant ?',
  'confirmation.deleteGarant.action': 'Supprimer',
  'confirmation.deleteContact.title': 'Supprimer un contact',
  'confirmation.deleteContact.message': 'La suppression d’un contact est définitive et irréversible.<br/>'
    + '<br/>'
    + 'Êtes-vous certain de vouloir supprimer ce contact ?',
  'confirmation.deleteContact.action': 'Supprimer',
  'candidature.delete': 'Supprimer la candidature',
  'property.assignAgent': 'Assigner à un agent',
  'confirmation.noAgent.title': 'Attention !',
  'confirmation.noAgent.message': 'L\'annonce que vous venez de créer n\'a pas d\'agent assigné. Toutes les notifications sur les candidatures relatives à cette annonce seront envoyées vers votre adresse email alternative %{mail}',
  'menuCandidate.summary': 'Récapitulatif',
  'menuCandidate.details': 'Détails candidature',
  'menuCandidate.documents': 'Documents',
  'relationship.SOLO': 'Une seule personne',
  'relationship.COUPLE': 'Couple',
  'relationship.FLATSHARING': 'Colocation',
  'relationship.question': 'Quelle est la relation entre les locataires ?',
  'summary.caseIncome': 'Revenus foyer',
  'summary.garants': 'Garant(s)',
  'summary.unknown': 'Non renseigné',
  'candidate.noAnnounceLink': 'Le candidat n\'a pas trouvé l\'annonce sur laquelle il souhaite candidater dans la liste. Voici le lien de l’annonce renseigné par le candidat :',
  'candidate.noAnnounceLinkAdd': 'Vous pouvez soit :<br/>- sélectionner l\'annonce correspondante dans la liste ci-dessous si elle existe<br/>- Soit créer l\'annonce en',
  'candidate.noAnnounce': 'Attention, nous n\'avons pas détecté d\'annonce au sein de votre agence pour "%{description}". Vous pouvez soit sélectionner l\'annonce correspondante dans la liste ci-dessous si elle existe, soit créer l\'annonce en',
  'formButton.clickHere': 'cliquant ici',
  'summary.missingRevenues': 'Revenus foyer non renseignés',
  'summary.missingProfessionalSituation': 'Situation pro. non renseignée',
  'summary.noMessage': 'Pas de message',
  'summary.professionalSituation': 'Situation pro',
  'summary.unknownProfessionalSituation': 'Situation pro non renseignée',
  'summary.noGarant': 'Pas de garant',
  'summary.addedBy': 'Ajouté par',
  'summary.garantUnknownRevenus': 'Garant(s) - valeur non précisée',
  'summary.type': 'Type(s)',
  'summary.gliEgible': 'Eligible à la GLI',
  'summary.pinelEligible': 'Eligible au dispositif Pinel',
  'summary.garantPersonRevenus': 'Revenus garants personne physique',
  'confirmation.noAgent.messageNoMail': 'Attention ! L\'annonce que vous venez de créer n\'a pas d\'agent assigné. Aucun agent ne sera notifié pour les candidatures liées à ces annonces. L\'administrateur du compte peut renseigner une adresse email alternative pour recevoir les notifications.',
  'personalSituations.DIVORCED': 'Divorcé(e)',
  'personalSituations.PACS': 'Pacsé(e)',
  'backButton.confirmLeaving.message': 'Si vous quittez cette page, les modifications qui n\'ont pas été sauvegardées seront définitivement perdues !',
  'backButton.confirmLeaving.title': 'Êtes-vous certain de vouloir quitter la page ?',
  'backButton.confirmLeaving.action': 'Je perds mes modifications',
  'document.section.ID': 'Identité',
  'document.section.OTHER': 'Autres',
  'document.section.TAX_NOTICE': 'Avis d\'imposition',
  'document.section.SITUATION': 'Situation professionnelle',
  'document.section.HOME': 'Domicile',
  'document.section.RESSOURCES': 'Ressources',
  'document.section.GUARANTEE_CERTIFICATE': 'Certificat de garantie',
  'document.section.EMPLOYER_PROOF': 'Justificatif(s) de garantie employeur',
  'document.section.GUARANTEE_COMMITMENT': 'Engagement de cautionnement',
  'document.tag.OTHER': 'Autres',
  'document.tag.ID': 'Passeport ou carte d\'identité',
  'document.tag.PAYSLIP_N1': 'Fiche de paie n-1',
  'document.tag.PAYSLIP_N2': 'Fiche de paie n-2',
  'document.tag.PAYSLIP_N3': 'Fiche de paie n-3',
  'document.tag.EMPLOYEMENT_CONTRACT': 'Contrat de travail',
  'document.tag.PROOF_OF_ACTIVTY': 'Justificatif d\'activité professionnelle',
  'document.tag.SCHOOL_CERTIFICATE': 'Certificat de scolarité',
  'document.tag.NOTICE_OF_SITUATION': 'Avis de situation',
  'document.tag.TAX_NOTICE': 'Avis d\'imposition',
  'document.tag.ADDRESS_PROOF': 'Justificatif de domicile',
  'document.tag.ACCOUNTING': 'Bilans comptables',
  'document.tag.RIGHT_NOTICE': 'Attestation de droits',
  'document.tag.OTHER_RESSOURCES_PROOF': 'Autre(s) justificatif(s) de ressources (facultatif)',
  'document.tag.RETIREMENT_SLIPS': 'Bulletins de retraite',
  'document.tag.RESSOURCES_PROOF': 'Justificatif de ressources (facultatif)',
  'document.tag.GUARANTEE_CERTIFICATE': 'Certificat de garantie',
  'document.tag.EMPLOYER_PROOF': 'Justificatif(s) de garantie employeur',
  'document.tag.GUARANTEE_COMMITMENT': 'Engagement de cautionnement',
  'candidature.informations': 'Informations',
  'candidature.documents': 'Documents clients',
  'candidature.documentsCopy': 'Documents agents',
  'confirmation.documentDelete.title': 'Supprimer un document',
  'confirmation.documentDelete.message': 'Vous êtes sur le point de supprimer un document. Cette suppression est irréversible.',
  'confirmation.NotCopy.title': 'Attention',
  'confirmation.NotCopy.message': 'Vous essayez d\'editer un dossier client. Veulliez créer une copie de ce dossier afin de pouvoir l\'editer. Attention, cette copie effacera la copie precedente que vous pourriez avoir sur ce contact.',
  'confirmation.NotCopy.action': 'Créer une copie',
  'mailType.BEFORE_VISIT': 'Avant une visite',
  'mailType.AFTER_VIST': 'Après une visite',
  'mailType.COMPLETE_CASE': 'Documents manquants',
  'mailType.REMINDER': 'Envoyer une relance',
  'documents.sendReminder': 'Voulez-vous envoyer une relance aux candidats ?',
  'documents.status.NOT_ASKED': 'Non demandés',
  'documents.status.ASKED': 'Demandés',
  'documents.status.REMINDED': 'Demandés',
  'documents.status.SENT': 'Transmis',
  'documents.status.MISSING': 'Manquants',
  'documents.status.VALIDATED': 'Validés',
  'documents.ask': 'Demander les documents',
  'documents.reminder': 'Envoyer un rappel',
  'documents.missing': 'Demander des documents manquants',
  'documents.validate': 'Valider les documents',
  'documents.validate.title': 'Valider les documents',
  'documents.validate.text': 'Voulez-vous valider les documents de cette candidature ?',
  'documents.subtitle.NOT_ASKED': '',
  'documents.subtitle.ASKED': 'il y a %{delta}',
  'documents.subtitle.REMINDED': 'Dernière relance il y a %{delta}',
  'documents.subtitle.SENT': 'il y a %{delta}',
  'documents.subtitle.MISSING': 'depuis %{delta}',
  'documents.subtitle.VALIDATED': 'il y a %{delta}',
  'propertiesFilter.noProperty': 'Sans bien lié',
  'dates.tomorrow': 'Demain',
  'visit.noVisit': 'Il n\'y a pas de visite pour ce bien pour l\'instant.',
  'info.notResponsive': 'Désolé, la version mobile n\'est pas encore au point…\n'
    + '\n'
    + '\n'
    + 'Nous faisons tout notre possible pour apporter Lokaa sur votre mobile le plus tôt possible !\n'
    + '\n'
    + '\n'
    + 'En attendant, nous vous invitons à utiliser l\'application depuis votre ordinateur.',
  'property.addByUrl': 'Ajouter par URL',
  'property.addManually': 'Ajout manuel',
  'property.noGliActivatedTitle': 'L\'agence n\'a pas activé de GLI !',
  'property.noGliActivatedContent': 'Vous n\'avez pas encore paramétré de contrat Garantie Loyers Impayés au sein de votre\n'
    + 'agence.',
  'property.clickHereGli': 'Cliquez ici pour accéder aux paramètres de la GLI',
  'gli.EMPLOYED_CDI_TRIAL': 'Salarié en CDI en période d\'essai',
  'gli.EMPLOYED_CDI_NO_TRIAL': 'Salarié en CDI hors période d\'essai',
  'gli.ALTERNANT': 'Alternant',
  'gli.INTERIMAIRE': 'Intérimaire',
  'gli.INTERMITTENT': 'Intermittent',
  'gli.CDD': 'Salarié en CDD',
  'gli.STUDENT': 'Etudiant / Stagiaire',
  'gli.RETIRED': 'Retraité',
  'gli.FREELANCER': 'Indépendant / Profession libérale',
  'gli.OFFICIAL': 'Fonctionnaire',
  'gli.JOB_SEEKER': 'Demandeur d\'emploi',
  'gli.tooltip.noContractActivated': 'La GLI de l\'agence est activée mais aucune situation professionnelle n\'a été activée.',
  'gli.tooltip.ratioLong': 'Ratio revenus / loyer',
  'gli.tooltip.ratioUndefined': '⚠️ Ratio non configuré',
  'gli.tooltip.totalCandidatureRevenues': 'Total revenus candidature éligibles à la GLI',
  'gli.tooltip.monthlyPrice': 'Loyer',
  'gli.tooltip.gliSuccess': 'La candidature est éligible à la GLI',
  'gli.tooltip.gliFail': 'La candidature n\'est pas éligible à la GLI',
  'gli.tooltip.incomes': 'Revenus minimum',
  'gli.ratio.minimumError': 'Le ratio doit être supérieur à 1 !',
  'gli.ratio.effortRate': 'Taux d\'effort de %{effortRate}%',
  'gli.tooltip.ratioSentance': '%{threshold} €/mois (%{ratio}x le montant du loyer)',
  'gli.tooltip.incomesVsRentPrice': '%{comparaison} à %{ratio}x le loyer',
  'gli.tooltip.monthlyRent': '%{propertyLease} €/mois',
  'pinel.composition': 'Composition du foyer',
  'pinel.tableTitle': 'Plafonds de ressources Loi Pinel métropole 2022',
  'pinel.zoneLong': 'Zone dont dépend le logement',
  'pinel.zone': 'Zone',
  'pinel.maxRevenues': 'Revenus maximum',
  'pinel.foyerRevenues': 'Revenus foyer',
  'pinel.table.zones': 'Zones',
  'pinel.zoneABis': 'A bis',
  'pinel.zoneRestA': 'Reste zone A',
  'pinel.zoneB1': 'B1',
  'pinel.zoneB2': 'B2',
  'pinel.zoneC': 'C',
  'pinel.zoneB2C': 'B2 et C',
  'pinel.missingData': '⚠️ Les revenus fiscaux du candidat et / ou la composition de son foyer en année N-2 sont manquants.',
  'pinel.SOLO': 'Personne seule',
  'pinel.COUPLE': 'Couple',
  'pinel.1': 'Personne seule ou couple ayant une personne à charge',
  'pinel.2': 'Personne seule ou couple ayant deux personnes à charge',
  'pinel.3': 'Personne seule ou couple ayant trois personnes à charge',
  'pinel.4': 'Personne seule ou couple ayant quatre personnes à charge',
  'pinel.5': 'Personne seule ou couple ayant cinq personnes à charge',
  'pinel.6': 'Personne seule ou couple ayant six personnes à charge',
  'pinel.7': 'Personne seule ou couple ayant sept personnes à charge',
  'pinel.8': 'Personne seule ou couple ayant huit personnes à charge',
  'pinel.9': 'Personne seule ou couple ayant neuf personnes à charge',
  'pinel.10': 'Personne seule ou couple ayant dix personnes à charge',
  'pinel.11': 'Personne seule ou couple ayant onze personnes à charge',
  'pinel.12': 'Personne seule ou couple ayant douze personnes à charge',
  'pinel.13': 'Personne seule ou couple ayant treize personnes à charge',
  'pinel.14': 'Personne seule ou couple ayant quatorze personnes à charge',
  'pinel.15': 'Personne seule ou couple ayant quinze personnes à charge',
  'pinel.NCHILD': 'Personne seule ou couple ayant plus de 3 personnes à charge',
  'pinel.UNKNOWN': 'Composition du foyer inconnue',
  'pinel.from5thPerson': 'Majoration par personne à charge à partir de la cinquième',
  'property.gliTooltip.situation': 'Situations éligibles',
  'property.gliTooltip.ratio': 'Ratio revenus / loyer',
  'form.chooseMultiple': 'Vous pouvez choisir plusieurs options',
  'property.zoneToDefine': 'Veuillez renseigner le zonage du bien',
  'property.findZone': 'Connaître la zone dont dépend le logement',
  'pinel.tooltip.pinelSuccess': 'La candidature est bien éligible au dispositif Pinel',
  'pinel.tooltip.pinelFail': 'La candidature n\'est pas éligible au dispositif Pinel',
  'form.fiscalRevenueN2': 'Revenu fiscal de référence pour l\'année %{year}',
  'form.foyerCompositionN2': 'Composition du foyer pour l\'année %{year}',
  'field.error.incorrectRevenuesN2': 'Veuillez indiquer un montant correct',
  'pinel.missingDataDb': '⚠️ Informations Pinel manquantes',
};

export type PossibleTraduction = keyof typeof fr;

const frTranslation: Traduction = {
  language: 'fr',
  messages: fr,
};

export default frTranslation;
