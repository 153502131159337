import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import backend from 'network/BackendFetchAdapter';
import { CandidatureAPI } from 'types/forms/CandidatureForm';
import sessionManager from 'services/sessionManager';
import candidatureFilterService, { CandidatureFilterService } from 'services/filters/CandidatureFilterService';
import { CandidatureDetailled } from 'types/Candidature';
import { Modification } from 'types/Modification';

export function useContactBackend() {
  const queryClient = useQueryClient();
  const CreateContact = useMutation<CandidatureDetailled, any, CandidatureAPI>(
    async (contact) => {
      const response = await backend.post('/api/contacts/', contact);
      return response;
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['getCandidatures']);
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.agent_id, CandidatureFilterService.getOnePropertyId(candidatureFilterService.getFilter().properties)]);
      },
    },
  );

  const GetContactModification = (
    contactId: string,
    enabled = true,
  ) => useQuery<{ [field: string]: Modification[] }>(
    ['getContactModification', sessionManager.getSession()?.agent_id, contactId],
    async () => {
      const response: Modification[] = await backend.fetchJson(
        `/api/contacts/${contactId}/modifications`,
      );

      return response?.reduce((acc, value) => {
        if (!acc[value.field]) {
          acc[value.field] = [];
        }
        acc[value.field].push(value);
        return acc;
      }, {}) as { [field: string]: Modification[] };
    },
    { enabled },
  );

  return {
    createContact: CreateContact,
    getContactModification: GetContactModification,
  };
}
