import React from 'react';
import NavItem from 'pages/agency-app/layout/NavItem';
import Messages from 'services/i18n/Messages';
import {
  ChartPieIcon,
  HomeIcon,
  LightningBoltIcon,
} from '@heroicons/react/solid';
import {
  AGENDA,
  AGENDA_BASE,
  APPARTMENT,
  CANDIDATE,
  DASHBOARD,
  PARAMETERES,
  PARAMETERES_PLAN,
} from 'routes/Routes';
import sessionService from 'services/sessionService';
import logo from 'assets/images/logo.png';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import AgentUtils from 'services/AgentUtils';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  MoreVert,
  ViewAgenda,
} from '@material-ui/icons';
import Button from 'theme/Button';
import AgencySelector from 'pages/common/AgencySelector';
import Image from 'theme/Image';
import {
  Link,
  useHistory,
} from 'react-router-dom';
import { useUserQueries } from 'network/queries/userQueries';
import DialogWrapper from 'pages/common/DialogWrapper';

function NavBar() {
  const history = useHistory();
  const agencyQueries = useAgencyBackend();
  const { getCurrentAgency } = agencyQueries;
  const { data: agency } = getCurrentAgency();
  const agentQueries = useAgentsBackend();
  const { getAgent } = agentQueries;
  const { data: agent } = getAgent();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [changeAccountModal, setChangeAccountModal] = React.useState(false);
  const userQueries = useUserQueries();
  const { getUserAgents } = userQueries;
  const { data: agents } = getUserAgents();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  let progress = (agency && ((agency.visitSlotCount || 0) / agency.plan.maxFreeVisitSlot) * 100)
    || 0;
  if (progress > 100) {
    progress = 100;
  }

  return (
    <nav className="nav-bar">
      <div className="nab-bar-header">
        <img className="logo" src={logo} alt="Lokaa" />
      </div>
      <div className="nav-bar-items">
        <NavItem label={Messages.t('navBar.dashboard')} icon={<ChartPieIcon />} exact path={DASHBOARD} />
        <NavItem label={Messages.t('navBar.appartment')} icon={<HomeIcon />} path={APPARTMENT} />
        <NavItem label={Messages.t('navBar.agenda')} icon={<ViewAgenda />} path={AGENDA} matchingPath={AGENDA_BASE} />
        <NavItem label={Messages.t('navBar.candidates')} icon={<LightningBoltIcon />} path={CANDIDATE} />
      </div>
      <div className="nav-bar-bottom-container">
        {
          agency && !agency.plan.visitSlotPlan && progress > 70 && (
            <div className="plan-info-container">
              {progress === 100 ? Messages.t('plan.visitCount.reach') : Messages.t('plan.visitCount.almostReach')}
              <div className="plan-progress-container">
                <LinearProgress
                  variant="determinate"
                  value={progress}
                />
                <div className="plan-subtitle">
                  {Messages.t('plan.visitSlot', { total: agency.plan.maxFreeVisitSlot, current: agency.visitSlotCount })}
                </div>
              </div>
              <Link
                className="link-as-button secondary"
                to={PARAMETERES_PLAN}
              >
                {Messages.t('plan.premium')}
              </Link>
              <div className="plan-subtitle plan-info">{Messages.t('plan.premium.advantages')}</div>
            </div>
          )
        }
        {
          agency && agent && (
            <div role="presentation" onClick={handleClick} className="agent-card">
              <div>
                <div className="rounded-image">
                  <Image alt="agent" src={agency?.imageURL} />
                </div>
              </div>
              <div className="agent-informations">
                <div>
                  {agency.name}
                </div>
                <div className="agent-name">
                  {AgentUtils.getPrettyName(agent)}
                </div>
              </div>
              <div>
                <IconButton onClick={handleClick}>
                  <MoreVert />
                </IconButton>
              </div>
            </div>
          )
        }
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {agents && agents.length > 1 && (
          <MenuItem
            onClick={() => {
              handleClose();
              setChangeAccountModal(true);
            }}
          >
            {Messages.t('navBar.changeAccount')}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            history.push(PARAMETERES);
            handleClose();
          }}
        >
          {Messages.t('navBar.parameters')}
        </MenuItem>
        <MenuItem
          onClick={() => sessionService.logOut()}
          className="danger-item"
        >
          {Messages.t('button.logout')}
        </MenuItem>
      </Menu>
      <DialogWrapper open={changeAccountModal} onClose={() => setChangeAccountModal(false)}>
        <DialogTitle>{Messages.t('navBar.changeAccount')}</DialogTitle>
        <DialogContent>
          <AgencySelector onValidate={() => setChangeAccountModal(false)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChangeAccountModal(false)}>
            {Messages.t('formButton.cancel')}
          </Button>
        </DialogActions>
      </DialogWrapper>
    </nav>
  );
}

export default NavBar;
