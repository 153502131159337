import React from 'react';
import { Document, Page } from 'react-pdf';

type Props = {
  src?: string,
};

export default function PdfPreview(
  {
    src,
  }: Props,
) {
  return (
    <>
      <Document
        file={src}
      >
        <Page pageNumber={1} />
      </Document>
    </>
  );
}
