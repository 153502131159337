import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import Image from 'theme/Image';

type Props = {
  src?: string,
  isPdf?: boolean;
};

export default function FileDisplay({ src, isPdf }: Props) {
  const [pageNumber, setPageNumber] = useState<number | undefined>();
  return (
    <div className="file-display">
      {
        isPdf ? (
          <Document
            file={src}
            onLoadSuccess={({ numPages }) => setPageNumber(numPages)}
          >
            {
              pageNumber && [...Array(pageNumber)].map((_, index) => (
                <Page pageNumber={index + 1} />
              ))
            }

          </Document>
        ) : (
          <Image alt="document" src={src} />
        )
      }
    </div>
  );
}
