const settings = {
  backendEndpoint: process.env.REACT_APP_BACKEND,
  env: process.env.REACT_APP_ENV,
  firebaseConfig: {
    apiKey: 'AIzaSyBl-nAuRJz_vQntfZ4FHGwON02ClGFOpXE',
    authDomain: 'kaliz-onboarding-250307.firebaseapp.com',
    projectId: 'kaliz-onboarding-250307',
    storageBucket: 'kaliz-onboarding-250307.appspot.com',
    messagingSenderId: '157360173145',
    appId: '1:157360173145:web:f22b3682f4435790630148',
  },
  sentryDns: 'https://ffd103779529434cb8bc8554c812fcc5@o4504813593755648.ingest.sentry.io/4505052953444352',
  googleMapsApiKey: 'AIzaSyC_NGIvqDiz6cUXuCw47oK5zE2sW_jk_0s',
  intercomKey: 'kyjnxdm8',
};

export default settings;
