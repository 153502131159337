import React from 'react';
import AgencyParameteres from 'pages/agency-app/parameters/agencyParameteres/AgencyParameteres';
import {
  PARAMETERES,
  PARAMETERES_AGENCY,
  PARAMETERES_INTEGRATION,
  PARAMETERES_MANAGE,
  PARAMETERES_PLAN,
  PARAMETERES_QUALIFICATION_OCCUPANTS,
  PARAMETERES_REDIRECTION,
} from 'routes/Routes';
import Messages from 'services/i18n/Messages';
import {
  Link,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import ProfileParameteres from 'pages/agency-app/parameters/profilParameters/ProfileParameteres';
import IntegrationsParameters from 'pages/agency-app/parameters/integrationsParameters/IntegrationsParameters';
import sessionManager from 'services/sessionManager';
import { permissionsEnum } from 'types/Permission';
import ManagerParameters from 'pages/agency-app/parameters/managerParameters/ManagerParameters';
import PlanParameters from 'pages/agency-app/parameters/planParameters/PlanParameters';
import OccupantQualification from 'pages/agency-app/parameters/occupantQualification/OccupantQualification';
import CallForwarding from './callForwarding/CallForwarding';

export default function Parameters() {
  const location = useLocation();
  return (
    <>
      <div className="page-header">
        <div className="page-selector-container">
          <Link
            className={`page-selector ${location.pathname === PARAMETERES ? 'selected' : ''}`}
            to={PARAMETERES}
          >
            {Messages.t('pageSelector.profil')}
          </Link>
          <Link
            className={`page-selector ${location.pathname === PARAMETERES_AGENCY ? 'selected' : ''}`}
            to={PARAMETERES_AGENCY}
          >
            {Messages.t('pageSelector.agency')}
          </Link>
          <Link
            className={`page-selector ${location.pathname === PARAMETERES_QUALIFICATION_OCCUPANTS ? 'selected' : ''}`}
            to={PARAMETERES_QUALIFICATION_OCCUPANTS}
          >
            {Messages.t('pageSelector.occupantQualification')}
          </Link>
          <Link
            className={`page-selector ${location.pathname === PARAMETERES_REDIRECTION ? 'selected' : ''}`}
            to={PARAMETERES_REDIRECTION}
          >
            {Messages.t('pageSelector.redirection')}
          </Link>
          <Link
            className={`page-selector ${location.pathname === PARAMETERES_INTEGRATION ? 'selected' : ''}`}
            to={PARAMETERES_INTEGRATION}
          >
            {Messages.t('pageSelector.integrations')}
          </Link>
          {
            sessionManager.getSession()?.permissions.includes(permissionsEnum.AGENCY_MANAGER) && (
              <Link
                className={`page-selector ${location.pathname === PARAMETERES_PLAN ? 'selected' : ''}`}
                to={PARAMETERES_PLAN}
              >
                {Messages.t('pageSelector.plans')}
              </Link>
            )
          }
          {
            sessionManager.getSession()?.permissions.includes(permissionsEnum.AGENCY_MANAGER) && (
              <Link
                className={`page-selector ${location.pathname === PARAMETERES_MANAGE ? 'selected' : ''}`}
                to={PARAMETERES_MANAGE}
              >
                {Messages.t('pageSelector.manage')}
              </Link>
            )
          }
        </div>
      </div>
      <div className="page-body">
        <Switch>
          <Route
            exact
            path={PARAMETERES}
            render={() => (
              <ProfileParameteres />
            )}
          />
          <Route
            exact
            path={PARAMETERES_AGENCY}
            render={() => (
              <AgencyParameteres />
            )}
          />
          <Route
            exact
            path={PARAMETERES_INTEGRATION}
            render={() => (
              <IntegrationsParameters />
            )}
          />
          <Route
            exact
            path={PARAMETERES_QUALIFICATION_OCCUPANTS}
            render={() => (
              <OccupantQualification />
            )}
          />
          <Route
            exact
            path={PARAMETERES_REDIRECTION}
            render={() => (
              <CallForwarding />
            )}
          />
          {
            sessionManager.getSession()?.permissions.includes(permissionsEnum.AGENCY_MANAGER) && (
              <Route
                path={PARAMETERES_PLAN}
                render={() => (
                  <PlanParameters />
                )}
              />
            )
          }
          {
            sessionManager.getSession()?.permissions.includes(permissionsEnum.AGENCY_MANAGER) && (
              <Route
                exact
                path={PARAMETERES_MANAGE}
                render={() => (
                  <ManagerParameters />
                )}
              />
            )
          }
        </Switch>
      </div>
    </>
  );
}
