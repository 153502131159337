import React, { useState } from 'react';
import imagePlaceHolder from 'assets/images/imagePlaceholder.svg';
import { useFilesBackend } from 'network/queries/FileQueries';

type Props = {
  src?: string
  alt: string
};

export default function Image({ src, alt }: Props) {
  const { getFile } = useFilesBackend();
  const { data: fileUrl } = getFile(src || '', !!src);
  const [hasError, setHasError] = useState(false);
  if (hasError || !src) {
    return (<img src={imagePlaceHolder} alt={alt} />);
  }
  return (
    <img onError={() => setHasError(true)} src={fileUrl} alt={alt} />
  );
}
