import { useQuery } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessionManager';

export function useFilesBackend() {
  const GetFile = (
    fileId: string,
    enabled = true,
  ) => useQuery<string>(
    ['getFile', sessionManager.getSession()?.user_id, fileId],
    async () => {
      if (fileId?.startsWith('http') || fileId?.startsWith('blob')) {
        return fileId;
      }
      const response = await backend.fetchBlob(
        `/api/documents/${fileId}`,
      );
      return window.URL.createObjectURL(response);
    },
    { enabled },
  );

  return {
    getFile: GetFile,
  };
}
