import { Candidature } from 'types/CandidatureBase';
import { FileUpload } from 'types/FileUpload';

export interface Property {
  id: string,
  agencyId: string,
  price: number | null,
  area: number | null,
  address?: string,
  rooms: number | null,
  online: boolean,
  isArchived: boolean,
  floor: number | null,
  bedrooms: number | null,
  bathrooms: number | null,
  furnished: boolean | null,
  description?: string,
  identifier: string,
  latitude?: number,
  longitude?: number,
  city?: string,
  zipCode?: string,
  images?: PropertyImage[]
  agentID?: string,
  createdAt?: string,
  updatedAt?: string,
  lastPublishAt?: string,
  lastUnpublishAt?: string,
  url?: string,
  gliEnabled?: boolean,
  pinelEnabled?: boolean,
  zone?: PropertyZone,
}

export interface PropertyDetailled extends Property {
  candidatures?: Candidature[],
}

export interface ImageData {
  id: string,
  imageURL: string,
  createdAt?: string,
  updatedAt?: string
  order?: number,
}

export interface PropertyImage extends ImageData {
  propertyID: string,
}

export interface PropertyPartial extends Partial<Property> {
  id: string,
}

export type PropertyZone = 'zoneABis' | 'zoneRestA' | 'zoneB1' | 'zoneB2' | 'zoneC';

export type PropertyZoneEnum = {
  [key in PropertyZone]: key
};

export const propertyZoneEnum: PropertyZoneEnum = {
  zoneABis: 'zoneABis',
  zoneRestA: 'zoneRestA',
  zoneB1: 'zoneB1',
  zoneB2: 'zoneB2',
  zoneC: 'zoneC',
};

export interface PropertyForm {
  id: string,
  agencyId: string,
  price: number | null,
  isArchived: boolean,
  area: number | null,
  address?: string,
  rooms: number | null,
  online: boolean,
  floor: number | null,
  imagesToUpload: FileUpload[]
  bedrooms: number | null,
  bathrooms: number | null,
  furnished: string | null,
  description: string,
  identifier: string,
  latitude?: number,
  longitude?: number,
  city: string,
  zipCode: string
  images?: PropertyImage[],
  agentID: string,
  imageUrls: string[],
  gliEnabled?: boolean,
  pinelEnabled?: boolean,
  zone?: PropertyZone,
  enableVisitAutomation?: boolean
}

export interface PropertyActionsType {
  id: string,
  agencyId: string,
  price: number | null,
  area: number | null,
  address?: string,
  rooms: number | null,
  online: string | null,
  isArchived: string | null,
  floor: number | null,
  bedrooms: number | null,
  bathrooms: number | null,
  furnished: boolean | null,
  description?: string,
  identifier: string,
  latitude?: number,
  longitude?: number,
  city?: string,
  zipCode?: string,
  images?: PropertyImage[]
  agentID: string,
  createdAt?: string,
  updatedAt?: string,
  lastPublishAt?: string,
  lastUnpublishAt?: string,
}
